window.initAdminFunction = function initAdminFunction() {
	$('#admin-section').on('click', '.btn-process-order', function () {
		let orderCode = $(this).parent().parent().find('td:eq(1)').text()
		$('#processOrderModalTitle').text('Process Order ' + orderCode)

		$('#processOrderModal select').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
			$(this).next().removeAttr('title')
		})
		$('#processOrderModal select').next().removeAttr('title')

		$('#process_olinterview_id').on("changed.bs.select", function (e, clickedIndex, newValue, oldValue) {
			if (clickedIndex === 0) {
				$('#process_interview_key_container').hide()
				$('#process_id_battery_container').show()
			} else if (clickedIndex === 1) {
				$('#process_interview_key_container, #process_id_battery_container').show()
			} else {
				$('#process_id_battery_container').hide()
				$('#process_interview_key_container').show()
			}
		})
	})

	$('#id-client-control, #limit-control').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
		$(this).next().removeAttr('title')
	})
	$('#id-client-control, #limit-control').next().removeAttr('title')

	$('#admin-section').on('click', '.btn-update-candidate', function () {
		let dayToday = moment().startOf('day')

		$('#updateOrderModal select').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
			$(this).next().removeAttr('title')
		})
		$('#updateOrderModal select').next().removeAttr('title')

		$('#update_olinterview_id').on("changed.bs.select", function (e, clickedIndex, newValue, oldValue) {
			if (clickedIndex === 0) {
				$('#update_interview_key_container').hide()
				$('#update_id_battery_container').show()
			} else if (clickedIndex === 1) {
				$('#update_interview_key_container, #update_id_battery_container').show()
			} else {
				$('#update_id_battery_container').hide()
				$('#update_interview_key_container').show()
			}
		})

		$('.start_period_update').datetimepicker({
			format: 'DD-MM-YYYY HH:mm',
			minDate: dayToday,
			readonly: true,
			ignoreReadonly: true
		})
		$('.end_period_update').datetimepicker({
			format: 'DD-MM-YYYY HH:mm',
			minDate: dayToday,
			useCurrent: false,
			readonly: true,
			ignoreReadonly: true
		})

		$('#updateOrderModal').on('change.datetimepicker', '.start_period_update', function (e) {
			$(this).addClass('active').parent().parent().parent().find('.end_period_update').datetimepicker('minDate', e.date)
		})

		$('#updateOrderModal').on('change.datetimepicker', '.end_period_update', function (e) {
			$(this).addClass('active').parent().parent().parent().find('.start_period_update').datetimepicker('maxDate', e.date)
		})
	})

	$('#admin-section').on('click', '.btn-upload-result', function () {
		function readFile(input) {
			if (input.files) {
				let reader = new FileReader();

				reader.onload = function (e) {
					let htmlPreview = '<ul class="text-start ps-4">'
					for (key in input.files) {
						if (input.files.hasOwnProperty(key)) {
							htmlPreview += '<li>' + input.files[key].name + '</li>'
						}
					}
					htmlPreview += '</ul>'

					let wrapperZone = $(input).parent()
					let previewZone = wrapperZone.parent().find('.preview-zone')
					let boxZone = previewZone.find('.box-body')

					wrapperZone.removeClass('dragover')
					previewZone.show()
					boxZone.empty()
					boxZone.append(htmlPreview)
					$('#uploadResultForm').find('button[type="submit"]').show()
				}

				reader.readAsDataURL(input.files[0])
			}
		}
		$(".dropzone").on('change', function () {
			readFile(this)
		})
		$('.dropzone-wrapper').on('dragover', function (e) {
			e.preventDefault()
			e.stopPropagation()
			$(this).addClass('dragover')
		})
		$('.dropzone-wrapper').on('dragleave', function (e) {
			e.preventDefault()
			e.stopPropagation()
			$(this).removeClass('dragover')
		})
		$('.remove-preview').on('click', function () {
			var previewZone = $(this).parents('.preview-zone')
			var boxZone = previewZone.find('.box-body')
			boxZone.empty()
			previewZone.hide()
			$('#uploadResultForm')[0].reset()
			$('#uploadResultForm').find('button[type="submit"]').hide()
		})

		$('#uploadResultModal').on('click', '.remove-uploaded', function () {
			$(this).parent().remove()
			if ($('#uploadResultModal').find('.remove-uploaded').length === 0) {
				$('#uploadResultModal').find('h5').text('No File Uploaded.')
			}
		})

		$('#flexCheckCheckedAll').click(function(){
            if(this.checked){
                alert('checked')
            }else{
                alert('unchecked')
            }
        });
	})

	$('#admin-section').on('click', '.btn-edit-user', function () {
		var self = $(this);
        var target = self.attr('data-mdb-target')

        $(target+' input').val('');
        $(target+' select').val('');
    })

	document.getElementById('updateCandidateForm').onsubmit = function () {
		$('#updateCandidateForm .confirm-fire').trigger('click')
		return false;
	};
}