import './bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.min';
import * as mdb from 'mdb-ui-kit';
import 'inputmask/dist/jquery.inputmask';
import 'jquery-validation/dist/jquery.validate.min';
// import 'bootstrap-tagsinput/dist/bootstrap-tagsinput';
window.moment = require('moment');
window.datetimepicker = require('./tempusdominus-bootstrap-4');
import 'tokenize2/tokenize2';
import './jquery-ui';
import './client-function';
import './admin-function';
import './client-interview-function';
import './custom';

window.Swal = Swal;
window.mdb = mdb;

// import Alpine from 'alpinejs';

// window.Alpine = Alpine;

// Alpine.start();
