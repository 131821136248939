$(function () {
  $('.preloader').fadeOut(800, function () {
    $('body').removeClass('loading-bg')
  })

  $('body').on('click', '.confirm-fire', function () {
    var self = $(this);

    var id = self.attr('data-id');
    var target = self.attr('data-target');
    var message = self.attr('data-message');

    Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#00B74A',
      cancelButtonColor: '#F93154',
      confirmButtonText: '<i class="fa-solid fa-circle-check me-2"></i>Yes',
      cancelButtonText: '<i class="fa-solid fa-circle-xmark me-2"></i>No',
      customClass: {
        confirmButton: 'w-100px rounded-pill btn',
        cancelButton: 'w-100px rounded-pill btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeof target == 'undefined') {
          document.getElementById('id-delete-' + id).click();
        } else {
          document.getElementById(target).click();
        }
      }
    })

  });
})

const initializeSidebarCollapse = () => {
  const sidebarElement = document.getElementsByClassName("js-sidebar")[0]
  const sidebarToggleElement = document.getElementsByClassName("js-sidebar-toggle")[0]

  if (sidebarElement && sidebarToggleElement) {
    sidebarToggleElement.addEventListener("click", () => {
      sidebarElement.classList.toggle("collapsed")

      sidebarElement.addEventListener("transitionend", () => {
        window.dispatchEvent(new Event("resize"))
      })
    })
  }
}

// Wait until page is loaded
document.addEventListener("DOMContentLoaded", () => initializeSidebarCollapse())

window.addEventListener('modalFlashSuccess', event => {
  let attribute_id = event.detail.attribute_id;
  let message = event.detail.message;
  let object = $(attribute_id).find('.alert-box').first();

  object.html('<div class="alert alert-success py-3"><i class="fa-solid fa-circle-check me-2" style="font-size: 1.25rem;"></i>' + message + '</div>');
  setTimeout(() => {
    object.find('.alert').fadeOut(1000);
  }, 3000);
})
window.addEventListener('modalFlash', event => {
  let attribute_id = event.detail.attribute_id;
  let message = event.detail.message;

  let timeout = 3000;
  if (typeof event.detail.timeout != 'undefined') {
    timeout = event.detail.timeout;
  }

  let object = $(attribute_id).find('.alert-box').first();

  object.html('<div class="alert alert-danger py-3"><i class="fa-solid fa-circle-exclamation me-2" style="font-size: 1.25rem;"></i>' + message + '</div>');
  setTimeout(() => {
    object.find('.alert').fadeOut(1000);
  }, timeout);
})

window.addEventListener('closeModal', event => {
  let attribute_id = event.detail.attribute_id;

  $(attribute_id).modal('hide');
})

window.addEventListener('updateNotchWidth', event => {
  document.querySelectorAll('.form-outline').forEach((formOutline) => {
    new mdb.Input(formOutline).update();
  })
})