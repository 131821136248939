window.initInterviewFunction = function initInterviewFunction() {
    $("#form_interview").on("change", "#client", function () {        
        getCurrentInterviews($(this).val())
        
        $("#response").html("")
	})

    $("#form_interview").on("submit", function (e) {
        e.preventDefault()

        let data = $(this).serialize()

        $.ajax({
            url: "/admin/interviews",
            type: "POST",
            data: data,
            dataType: 'json',
            success: function(response) {
                let alert = ""

                if (response.status == "ok") {
                    alert = "success"
    
                    getCurrentInterviews($("#client").val())
                }
                else {
                    alert = "warning"
                }

                $("#response").html("<div class='alert alert-" + alert + " alert-dismissible fade show mt-3' role='alert' id='response'>" + response.message + "<button type='button' class='btn-close' data-bs-dismiss='alert'></button></div>")
            },
            error: function (err) {
                if (err.status == 422) {
                    html = ""

                    
                    $.each(err.responseJSON.errors, function (key, value) {
                        html += "<li>" + value + "</li>"
                    })

                    $("#response").html("<div class='alert alert-danger alert-dismissible fade show mt-3' role='alert' id='response'>Error: <ol>" +html + "</ol><button type='button' class='btn-close' data-bs-dismiss='alert'></button></div>")
                }
            }
        })
    })

    $("#form_interview").on("click", ".btn_delete", function () {
        let id = $(this).data("id")
        let interview = $(this).data("interview")

        $("#form_delete #id").val(id)
        $("#modal_delete .modal-body").html("Are you sure want to detach <b>" + interview + "</b> for this client?")
    })

    $("#form_delete").on("submit", function (e) {
        e.preventDefault()

        let data = $(this).serialize()

        $.ajax({
            url: "/admin/interviews/" + $("#id").val(),
            type: "DELETE",
            data: data,
            dataType: 'json',
            success: function(response) {
                let alert = ""

                if (response.status == "ok") {
                    alert = "success"
    
                    getCurrentInterviews($("#client").val())
                }
                else {
                    alert = "warning"
                }

                $("#response").html("<div class='alert alert-" + alert + " alert-dismissible fade show mt-3' role='alert' id='response'>" + response.message + "<button type='button' class='btn-close' data-bs-dismiss='alert'></button></div>")
            }
        })
    })
}

function getCurrentInterviews(clientId)
{
    $.ajax({
        url: "/admin/interviews/" + clientId,
        type: "GET",
        dataType: 'json',
		beforeSend: function() {
            $("#current_interviews").html('<div class="text-primary"><i class="fa fa-spinner fa-spin"></i> Please Wait...</div>')
        },
        success: function(response) {
            let html = "No data"
            
            if (response.interviews.length > 0) {
                html = "<ol>"

                $.each(response.interviews, function (key, value) {
                    html += "<li class='mb-1'><button type='button' class='btn btn-danger btn-sm btn_delete me-1' data-id='" + value.id + "' data-interview='" + value.interview_title + "' data-bs-toggle='modal' data-bs-target='#modal_delete'><i class='fa fa-trash'></i></button>" + value.interview_title + "</li>"
                })
        
                html += "</ol>"
            }
    
            $("#current_interviews").html(html)
        }
    })
}
