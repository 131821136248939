window.initClientFunction = function initClientFunction() {
    let idx = 1
    let templateForm = ''
    let dayToday = moment().startOf('day')
    let positionLevelList = ['Staff', 'Senior Staff', 'Supervisor', 'Manager', 'Senior Manager', 'Executive']
    let purposeList = ['Recruitment', 'Development', 'Promotion']
    let genderList = ['Male', 'Female']
    let startPeriodUpdate, endPeriodUpdate
    let copyStartPeriod, copyEndPeriod, copyJobTitle, copyPositionLevel, copyPurpose;

    var autoFillTokenize = function(){
        
    }

    $('#limit-control').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
        $(this).next().removeAttr('title')
    })
    $('#limit-control').next().removeAttr('title')

    $('#btn-create-order').on('click', function () {
        idx = 3
        templateForm = ''
        generateFormTable()
        autoFillTokenize();
    })

    $('#btn-add-candidate').on('click', function () {
        idx++
        templateForm = addItemTemplateTable(idx)
        $('#tableOrderCandidateList').append(templateForm)
        $('.mobile_number_cls').inputmask()
        $('#createOrderModal select').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
            $(this).next().removeAttr('title')
            if ($(this).attr('id') === 'position_level_1') copyPositionLevel = $(this).val()
            if ($(this).attr('id') === 'purpose_1') copyPurpose = $(this).val()
            if ($(this).hasClass('gender') && $(this).attr('id') !== 'gender_1') {
                duplicateData($(this).parents('tr'))
            }
        })
        $('#createOrderModal select').next().removeAttr('title')
        $('#start_period_' + idx).datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            minDate: dayToday,
            readonly: true,
            ignoreReadonly: true,
            widgetParent: 'body'
        })
        $('#end_period_' + idx).datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            minDate: dayToday,
            useCurrent: false,
            readonly: true,
            ignoreReadonly: true,
            widgetParent: 'body'
        })
        updateTableItemIndex()
    })

    $('#tableOrderCandidateList').on('click', '.btn-delete-item-table', function () {
        $(this).parent().parent().remove()
        updateTableItemIndex()
    })

    $('#client-section').on('click', '.btn-result-candidate', function () {
        let cndName = $(this).parent().parent().parent().parent().parent().find('td:eq(2)').text()
        $('#resultOrderModalTitle').text(cndName + ' Result')
    })

    function generateFormTable() {
        /* set empty data */
        $('#tableOrderCandidateList').empty()
        copyStartPeriod = ''
        copyEndPeriod = ''
        copyJobTitle = ''
        copyPositionLevel = ''
        copyPurpose = ''
        /* set empty data */

        for (let i = 1; i <= idx; i++) {
            templateForm += addItemTemplateTable(i)
        }
        $('#tableOrderCandidateList').append(templateForm)
        $('.mobile_number_cls').inputmask()
        $('#createOrderModal select').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
            $(this).next().removeAttr('title')
            if ($(this).attr('id') === 'position_level_1') copyPositionLevel = $(this).val()
            if ($(this).attr('id') === 'purpose_1') copyPurpose = $(this).val()
            if ($(this).hasClass('gender') && $(this).attr('id') !== 'gender_1') {
                duplicateData($(this).parents('tr'))
            }
        })
        $('#createOrderModal select').next().removeAttr('title')

        $('#job_title_1').on('change', function () {
            copyJobTitle = $('#job_title_1').val()
        })

        /* set empty data */
        $('#language_id').val('').selectpicker('refresh');
        $('#id_test_candidate_id').val('').selectpicker('refresh');
        $('#proctoring_id').val('0').selectpicker('refresh');
        $('#olinterview_id').val('0').selectpicker('refresh');
        $('#interview_title_id').val('').selectpicker('refresh');
        $('#type_report_id').val('').selectpicker('refresh');
        $('#report_language_id').val('').selectpicker('refresh');
        $('#darya_notes_id').val('').selectpicker('refresh');
        $('#darya_company').val('');
        $('#id_result_sent_to').val('').removeClass('active');

        // $('#result_sent_to').tagsinput('removeAll');
        // $('#result_sent_to_cc').tagsinput('removeAll');
        /* set empty data */

        $('.start_period').datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            minDate: dayToday,
            readonly: true,
            ignoreReadonly: true,
            widgetParent: 'body'
        })
        $('.end_period').datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            minDate: dayToday,
            useCurrent: false,
            readonly: true,
            ignoreReadonly: true,
            widgetParent: 'body'
        })

        $('.id-test-combination-container, .interview-title-container').removeClass('d-none')
        $('.interview-title-container').addClass('d-none')
    }

    function addItemTemplateTable(i) {
        return '' +
            '<tr class="table-item" rel="' + i + '">' +
            '<td class="p-1 text-end">' + i + '</td>' +
            inputTextTable('first_name', i, 'first_name_cls') +
            inputTextTable('last_name', i, 'last_name_cls') +
            inputOptionTable('gender', i, genderList) +
            inputTextTable('email', i, 'email_cls') +
            inputTextTable('mobile_number', i, 'mobile_number_cls', 'data-inputmask="\'mask\': \'99999999999999\'"') +
            inputTextTable('start_period', i, 'start_period start_period_cls datetimepicker-input bg-white', 'data-toggle="datetimepicker" data-target="#start_period_' + i + '" autocomplete="off"') +
            inputTextTable('end_period', i, 'end_period end_period_cls datetimepicker-input bg-white', 'data-toggle="datetimepicker" data-target="#end_period_' + i + '" autocomplete="off"') +
            inputTextTable('job_title', i, 'job_title_cls') +
            inputOptionTable('position_level', i, positionLevelList) +
            inputOptionTable('purpose', i, purposeList) +
            (i !== 1 ? '<td class="p-1"><button class="btn btn-danger btn-floating btn-delete-item-table" rel="' + i + '"><i class="fa fa-xmark text-white"></i></button></td>' : '<td class="p-1"></td>') +
            '</tr>'
    }

    function updateTableItemIndex() {
        let index = 1
        $('.table-item').each(function () {
            $(this).find('td:first').text(index)
            index++
        })
    }

    function inputTextTable(target_id, sequence, target_class = '', target_custom = '') {
        return '<td class="p-1"><div class="position-relative"><input type="text" id="' + target_id + '_' + sequence + '" name="' + target_id + '_' + sequence + '" class="form-control rounded-pill ' + target_class + '" ' + target_custom + '></div></td>'
    }

    function inputOptionTable(target_id, sequence, inputOptionList) {
        return '<td class="p-1">' +
            '<div class="form-control active px-3 dropdown-bs-select-area rounded-pill" style="height: 31px;">' +
            '<select class="show-tick w-100 ' + target_id + ' ' + target_id + '_cls " id="' + target_id + '_' + sequence + '" name="' + target_id + '_' + sequence + '">' +
            '<option value="">-choose-</option>' +
            inputOptionItem(inputOptionList) +
            '</select>' +
            '</div>'
        '</td>'
    }

    function inputOptionItem(inputOptionList) {
        let inputOptionHTML = ''
        if (inputOptionList.length !== undefined) {
            for (let i = 0; i < inputOptionList.length; i++) {
                inputOptionHTML += '<option value="' + inputOptionList[i] + '">' + inputOptionList[i] + '</option>'
            }
        } else {
            for (const key in inputOptionList) {
                inputOptionHTML += '<option value="' + key + '">' + inputOptionList[key] + '</option>'
            }
        }
        return inputOptionHTML
    }

    $('#tableOrderCandidateList').on('change.datetimepicker', '.start_period', function (e) {
        $(this).parent().parent().parent().find('.end_period').datetimepicker('minDate', e.date)
        if ($(this).attr('id') === 'start_period_1') copyStartPeriod = e.date
    })

    $('#tableOrderCandidateList').on('show.datetimepicker', '.start_period', function (e) {
        let datepicker = $('body').find('.bootstrap-datetimepicker-widget:last')
        if (datepicker.hasClass('bottom')) {
            let top = $(this).offset().top + 31
            let left = $(this).offset().left
            datepicker.css({
                'top': top + 'px',
                'bottom': 'auto',
                'left': left + 'px'
            })
        } else if (datepicker.hasClass('top')) {
            let top = $(this).offset().top - datepicker.outerHeight()
            let left = $(this).offset().left
            datepicker.css({
                'top': top + 'px',
                'bottom': 'auto',
                'left': left + 'px'
            })
        }
    })

    $('#tableOrderCandidateList').on('change.datetimepicker', '.end_period', function (e) {
        $(this).parent().parent().parent().find('.start_period').datetimepicker('maxDate', e.date)
        if ($(this).attr('id') === 'end_period_1') copyEndPeriod = e.date
    })

    $('#tableOrderCandidateList').on('show.datetimepicker', '.end_period', function (e) {
        let datepicker = $('body').find('.bootstrap-datetimepicker-widget:last')
        if (datepicker.hasClass('bottom')) {
            let top = $(this).offset().top + 31
            let left = $(this).offset().left
            datepicker.css({
                'top': top + 'px',
                'bottom': 'auto',
                'left': left + 'px'
            })
        } else if (datepicker.hasClass('top')) {
            let top = $(this).offset().top - datepicker.outerHeight()
            let left = $(this).offset().left
            datepicker.css({
                'top': top + 'px',
                'bottom': 'auto',
                'left': left + 'px'
            })
        }
    })

    window.addEventListener('initFormEdit', event => {
        // $('.tagsinput-update').each(function () {
        //     var val = $(this).val();

        //     $(this).tagsinput('destroy');
        //     $(this).tagsinput('add', val);
        // })

        $('.mobile-number-edit').inputmask()
        $('#editOrderModal select').selectpicker({ style: 'btn-primary btn-select-custom p-0' }).on('change', function () {
            $(this).next().removeAttr('title')
        })
        $('#editOrderModal select').next().removeAttr('title')

        startPeriodUpdate = moment(event.detail.startPeriodUpdate, 'DD-MM-YYYY HH:mm').toDate()
        endPeriodUpdate = moment(event.detail.endPeriodUpdate, 'DD-MM-YYYY HH:mm').toDate()

        if (moment(startPeriodUpdate).isBefore(dayToday)) {
            $('#start_period_update').datetimepicker({
                date: startPeriodUpdate,
                format: 'DD-MM-YYYY HH:mm',
                maxDate: endPeriodUpdate,
                readonly: true,
                ignoreReadonly: true,
            })
        } else {
            $('#start_period_update').datetimepicker({
                date: startPeriodUpdate,
                format: 'DD-MM-YYYY HH:mm',
                minDate: dayToday,
                maxDate: endPeriodUpdate,
                readonly: true,
                ignoreReadonly: true,
            })
        }

        $('#end_period_update').datetimepicker({
            date: endPeriodUpdate,
            format: 'DD-MM-YYYY HH:mm',
            minDate: startPeriodUpdate,
            readonly: true,
            ignoreReadonly: true,
            useCurrent: false,
        })

        $('#editOrderModal').on('click', '#start_period_update, #end_period_update', function () {
            if (moment(startPeriodUpdate).isBefore(dayToday)) {
                startPeriodUpdate = dayToday
                if (moment(endPeriodUpdate).isBefore(dayToday)) {
                    endPeriodUpdate = moment(dayToday).add(1, 'days')

                    $('#end_period_update').datetimepicker('destroy')
                    $('#end_period_update').datetimepicker({
                        date: endPeriodUpdate,
                        format: "DD-MM-YYYY HH:mm",
                        minDate: startPeriodUpdate,
                        useCurrent: false,
                        readonly: true,
                        ignoreReadonly: true,
                    })
                }
                $('#start_period_update').datetimepicker('destroy')
                $('#start_period_update').datetimepicker({
                    date: startPeriodUpdate,
                    format: "DD-MM-YYYY HH:mm",
                    minDate: dayToday,
                    maxDate: endPeriodUpdate,
                    readonly: true,
                    ignoreReadonly: true,
                })
            }
        })

        $('#editOrderModal').on('change.datetimepicker', '.start_period_update', function (e) {
            $(this).addClass('active').parent().parent().parent().find('.end_period_update').datetimepicker('minDate', e.date)
        })

        $('#editOrderModal').on('change.datetimepicker', '.end_period_update', function (e) {
            $(this).addClass('active').parent().parent().parent().find('.start_period_update').datetimepicker('maxDate', e.date)
        })
    })

    window.addEventListener('showErrorCreate', event => {
        const errorObject = event.detail.errorList

        if (jQuery.isEmptyObject(errorObject)) {
            $('#tableOrderCandidateList').find('tr').each(function () {
                $(this).addClass('error-row').find('td').eq(0).prepend('<i class="fa-solid fa-circle-exclamation me-1 text-danger"></i>')
            })
        } else {
            for (const key in errorObject) {
                if (errorObject.hasOwnProperty(key)) {
                    $('#tableOrderCandidateList').find('tr').eq(key - 1).addClass('error-row').find('td').eq(0).prepend('<i class="fa-solid fa-circle-exclamation me-1 text-danger"></i>')
                }
            }
        }
    })

    window.addEventListener('clearErrorCreate', event => {
        $('#tableOrderCandidateList').find('tr').each(function () {
            $(this).removeClass('error-row').find('td i.fa-solid').remove()
        })
    })

    window.addEventListener('initFormAddTest', event => {
        startPeriodAddTest = moment(event.detail.startPeriodAddTest, 'DD-MM-YYYY HH:mm').toDate()
        endPeriodAddTest = moment(event.detail.endPeriodAddTest, 'DD-MM-YYYY HH:mm').toDate()

        if (moment(startPeriodAddTest).isBefore(dayToday)) {
            $('#add-start-period').datetimepicker({
                date: startPeriodAddTest,
                format: 'DD-MM-YYYY HH:mm',
                maxDate: endPeriodAddTest,
                readonly: true,
                ignoreReadonly: true,
            })
        } else {
            $('#add-start-period').datetimepicker({
                date: startPeriodAddTest,
                format: 'DD-MM-YYYY HH:mm',
                minDate: dayToday,
                maxDate: endPeriodAddTest,
                readonly: true,
                ignoreReadonly: true,
            })
        }

        $('#add-end-period').datetimepicker({
            date: endPeriodAddTest,
            format: 'DD-MM-YYYY HH:mm',
            minDate: startPeriodAddTest,
            readonly: true,
            ignoreReadonly: true,
            useCurrent: false,
        })

        $('#addTestModal').on('change.datetimepicker', '#add-start-period', function (e) {
            $(this).addClass('active').parent().parent().parent().find('#add-end-period').datetimepicker('minDate', e.date)
        })

        $('#addTestModal').on('change.datetimepicker', '#add-end-period', function (e) {
            $(this).addClass('active').parent().parent().parent().find('#add-start-period').datetimepicker('maxDate', e.date)
        })
    })

    $('#tableOrderCandidateList').on('keyup', '.first_name_cls, .last_name_cls, .email_cls, .mobile_number_cls', function () {
        if ($(this).parents('tr').attr('rel') !== '1') duplicateData($(this).parents('tr'))
    })

    function duplicateData(parentElement) {
        if (parentElement.find('.start_period').val() === '') parentElement.find('.start_period').datetimepicker('date', copyStartPeriod)
        if (parentElement.find('.end_period').val() === '') parentElement.find('.end_period').datetimepicker('date', copyEndPeriod)
        if (parentElement.find('.job_title_cls').val() === '') parentElement.find('.job_title_cls').val(copyJobTitle)
        if (parentElement.find('.position_level').val() === '') parentElement.find('.position_level').val(copyPositionLevel).selectpicker('refresh')
        if (parentElement.find('.purpose').val() === '') parentElement.find('.purpose').val(copyPurpose).selectpicker('refresh')
    }

    window.addEventListener('endOfStoreCandidate', event => {
        $('#id-filename-import').text('');
        $('#fill-import-excel').val('');

        $('.token-autocomplete').trigger('tokenize:clear');
    })

    $("#createOrderFormTable").on("change", "#interview_title_id", function () {
        let key = $(this).find(':selected').data('key')
        $.ajax({
            url: "/interview?key=" + key,
            type: "GET",
            dataType: 'json',
            beforeSend: function() {
                $("#interview_questions").addClass('my-4').html('<div class="text-center text-primary"><i class="fa fa-spinner fa-spin"></i> Please Wait...</div>')
            },
            success: function(response) {
                if (response.status != "ok") {
                    $("#interview_questions").html(response.message)
                }
                else {
                    let html = "<div class='table-responsive'>"
                    html += "<table class='table table-striped table-bordered table-sm'>"
                    html += "<thead class='table-primary'>"
                    html += "<th>No.</th>"
                    html += "<th>Interview Question</th>"
                    html += "<th>Type</th>"
                    html += "<th>Max Duration (min)</th>"
                    html += "<th>Max Retakes (x)</th>"
                    html += "</thead>"
                    html += "<tbody>"

                    $.each(response.questions, function (key, value) {
                        html += "<tr>"
                        html += "<td>" + value.order + "</td>"
                        html += "<td>" + value.text + "</td>"
                        html += "<td>" + value.type + "</td>"
                        html += "<td>" + value.duration + "</td>"
                        html += "<td>" + value.retakes + "</td>"
                        html += "</tr>"
                    })

                    html += "</tbody>"
                    html += "</table>"
                    html += "</div>"

                    $("#interview_questions").html(html)
                }
            }
        })

        $("#createOrderFormTable").on("change", "#olinterview_id", function () {
            $("#interview_questions").html("")
        })
    })
}